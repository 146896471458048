<template>
  <AppLoginPageLayout switch-to-login>
    <div class="d-flex justify-center">
      <img
        src="@/assets/icons/logo_sourcery.svg">
    </div>
    <h1 class="form-login__container--title">
      Create Your Account
    </h1>
    <v-form @submit.prevent="signUpSubmit">
      <v-text-field
        v-model="signUp.name"
        placeholder="Name"
        single-line
        filled
        dense
        data-test="sign_up_name_input"
        :error-messages="nameErrors"
        :append-icon="!!nameErrors.length? 'mdi-alert-circle-outline' : ''"
        prepend-inner-icon="mdi-account-outline"
        :error="!!nameErrors.length"
        autocomplete="name" />
      <v-text-field
        v-model="initialCaseEmail"
        placeholder="Email"
        single-line
        type="email"
        filled
        dense
        data-test="sign_up_email_input"
        :error-messages="emailErrors"
        :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
        prepend-inner-icon="mdi-email-outline"
        :error="!!emailErrors.length "
        class="mt-6"
        autocomplete="email"
        @input="error = null" />
      <v-text-field
        v-model="signUp.password"
        placeholder="Password"
        single-line
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        filled
        dense
        data-test="sign_up_password_input"
        :error-messages="passwordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!passwordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showPassword = !showPassword" />
      <ForgotPassword />
      <AgreeTermsConditions :agree-terms-conditions.sync="agreeTermsConditions" />
      <v-row class="mt-8 d-flex justify-center flex-column align-center">
        <v-btn
          :disabled="!agreeTermsConditions"
          type="submit"
          data-test="sign_up_button"
          color="lightBlue">
          Sign up
        </v-btn>
        <p
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-2 small-p text-uppercase">
          <router-link :to="{ name: 'login' }">
            Log in
          </router-link>
        </p>
      </v-row>
    </v-form>
  </AppLoginPageLayout>
</template>
<script>
import { Auth } from 'aws-amplify';
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import AgreeTermsConditions from '@/components/SignUp/SignUpAgreeTermsConditions';
import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
import paymentAPI from '@/services/graphql/payments';
import ErrorsText from '@/constants/errors';
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import AWS from '@/services/awsSdk';
export default {
  name: 'SignUp',
  components: {
    AppLoginPageLayout,
    AgreeTermsConditions,
    ForgotPassword: () => import('@/components/App/AppLoginPageLayout/ForgotPassword'),
  },
  mixins: [ConverEmailToLowerCase],
  data: () => ({
    showPassword: false,
    agreeTermsConditions: false,
    error: null,
    pageObjName: 'signUp',
    signUp: {
      name: null,
      email: null,
      password: null,
      initialCaseEmail: null,
    },
  }),
  validations: {
    signUp: {
      name: {
        required,
      },
      email: {
        required, email,
      },
      password: {
        required, minLength: minLength(8),
      },
    },
  },
  computed: {
    ...mapState(['domain', 'isMobile']),
    nameErrors() {
      const errors = [];
      if (!this.$v.signUp.name.$dirty) return errors;
      if (!this.$v.signUp.name.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.signUp.email.$dirty) return errors;
      if (!this.$v.signUp.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.signUp.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error) {
        errors.push(this.error);
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.signUp.password.$dirty) return errors;
      if (!this.$v.signUp.password.minLength) {
        errors.push('Passwords must be at least 8 characters long');
      }
      if (!this.$v.signUp.password.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    viewParamsPayment() {
      const { tire, role, interval } = this.queryPayment;
      return [{
        id: 'viewId', value: 'payment',
      },
      {
        id: 'tire', value: tire,
      },
      {
        id: 'role', value: role,
      },
      {
        id: 'interval', value: interval,
      },
      {
        id: 'redirect', value: 'payment',
      },
      ];
    },
    isFreePlan() {
      return this.$route.query?.role === 'Free User';
    },
    queryPayment() {
      let queryPayment = {
      };
      const query = this.$route.query;
      query?.tire && query?.role && query?.interval ? queryPayment = query : queryPayment = null;
      return queryPayment;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError']),
    async signUpSubmit() {
      this.$v.signUp.$touch();
      if (!this.$v.signUp.$invalid && !this.error) {
        const { email, password, name } = this.signUp;
        this.spinner(true);
        try {
          const user = await Auth.signUp({
            username: email,
            password,
            attributes: {
              name,
            },
            clientMetadata: {
              mobile: this.isMobile.toString(),
              domain: this.domain,
            },
          });
          if (this.queryPayment && !this.isFreePlan) {
            try {
              const username = user.userSub;
              await paymentAPI.setViewParams({
                username,
                params: this.viewParamsPayment,
              });
            } catch (err) {
              this.handleError(err);
            }
          }
          this.forceLogin({
            email,
            password,
          });
        } catch (err) {
          this.spinner(false);
          if (err.message === 'An account with the given email already exists.') {
            this.error = err.message;
          }
        }
      }
    },
    forceLogin({ email, password } = {
    }) {
      if (email && password) {
        const dataToSend = {
          email,
          password,
        };
        AWS.login(dataToSend, this);
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
